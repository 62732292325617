import Vue from 'vue';

export function listGames(params) {
  return Vue.$http({
    url: '/games/listGames',
    method: 'get',
    params,
  });
}

export function getGame(params) {
  return Vue.$http({
    url: '/games/getGame',
    method: 'get',
    params,
  });
}

export function updateGame(data) {
  return Vue.$http({
    url: '/games/updateGame',
    method: 'post',
    data,
  });
}

export function createGame(data) {
  return Vue.$http({
    url: '/games/createGame',
    method: 'post',
    data,
  });
}

export function deleteGame(data) {
  return Vue.$http({
    url: '/games/deleteGame',
    method: 'post',
    data,
  });
}

export function getAllGames(params) {
  return Vue.$http({
    url: '/games/getAllGames',
    method: 'get',
    params,
  });
}

export function generateRelativeUrl(params) {
  return Vue.$http({
    url: '/games/generateRelativeUrl',
    method: 'get',
    params,
  });
}
