import Vue from 'vue';

export function listShops(params) {
  return Vue.$http({
    url: '/shops/listShops',
    method: 'get',
    params,
  });
}

export function getShop(params) {
  return Vue.$http({
    url: '/shops/getShop',
    method: 'get',
    params,
  });
}

export function updateShop(data) {
  return Vue.$http({
    url: '/shops/updateShop',
    method: 'post',
    data,
  });
}

export function createShop(data) {
  return Vue.$http({
    url: '/shops/createShop',
    method: 'post',
    data,
  });
}

export function deleteShop(data) {
  return Vue.$http({
    url: '/shops/deleteShop',
    method: 'post',
    data,
  });
}

export function getAllShops(params) {
  return Vue.$http({
    url: '/shops/getAllShops',
    method: 'get',
    params,
  });
}

export function generateRelativeUrl(params) {
  return Vue.$http({
    url: '/shops/generateRelativeUrl',
    method: 'get',
    params,
  });
}

export function getAllVariables() {
  return Vue.$http({
    url: '/shops/getAllVariables',
    method: 'get',
  });
}
