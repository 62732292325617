<template>
  <div class="flex-form">
    <el-form
      ref="shopForm"
      v-loading="loading"
      :model="shopForm"
      label-width="170px"
      :rules="rules"
      autocomplete="off"
    >
      <el-form-item>
        <h2 v-if="isEditMode">
          Edit Shop
        </h2>
        <h2 v-else-if="!isEditMode">
          Add Shop
        </h2>
      </el-form-item>

      <el-tabs
        v-model="formTabsValue"
        class="el-form-item__content"
      >
        <el-tab-pane
          label="Main"
          name="main"
        >
          <el-form-item
            v-if="isEditMode"
            label="Uid"
          >
            {{ shopUid }}
          </el-form-item>

          <el-form-item
            label="Active"
            prop="active"
          >
            <el-switch
              v-model="shopForm.isActive"
            />
          </el-form-item>

          <el-form-item
            label="Name"
            prop="shopName"
          >
            <el-input v-model="shopForm.shopName" />
          </el-form-item>

          <el-form-item
            label="Position"
            prop="position"
          >
            <el-input v-model="shopForm.position" />
          </el-form-item>

          <el-form-item
            label="Rating"
            prop="rating"
          >
            <el-rate
              v-model="shopForm.rating"
              show-score
              allow-half
            />
            <div>
              <el-input-number
                v-model="shopForm.rating"
                size="mini"
                controls-position="right"
              />
            </div>
          </el-form-item>

          <el-form-item
            label="API Provider"
            prop="provider"
          >
            <el-input v-model="shopForm.provider" />
          </el-form-item>

          <el-form-item
            label="Games"
            prop="gameUids"
          >
            <el-select
              v-model="shopForm.gameUids"
              multiple
              placeholder="Select"
            >
              <el-option
                v-for="item in gameOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>

          <el-form-item
            label="All Payments"
            prop="paymentUids"
          >
            <el-select
              v-model="shopForm.paymentUids"
              multiple
              placeholder="Select"
            >
              <el-option
                v-for="item in paymentOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>

          <el-form-item
            label="Game Page Payments"
            prop="gpPaymentUids"
          >
            <el-select
              v-model="shopForm.gpPaymentUids"
              multiple
              placeholder="Select"
            >
              <el-option
                v-for="item in paymentOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>

          <el-form-item
            label="Game Page Deposits"
            prop="gpDepositUids"
          >
            <el-select
              v-model="shopForm.gpDepositUids"
              multiple
              placeholder="Select"
            >
              <el-option
                v-for="item in paymentOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>

          <el-form-item
            label="Icon"
          >
            <upload-media
              ref="iconImage"
              :image-url="iconUrl"
              :params="{ contentType: 'SHOP_ICON' }"
              @success="handleIconUploadSuccess"
              @remove="handleIconUploadRemove"
            />
          </el-form-item>

          <el-form-item
            label="Logo"
          >
            <upload-media
              ref="logoImage"
              :image-url="logoUrl"
              :params="{ contentType: 'SHOP_LOGO' }"
              @success="handleLogoUploadSuccess"
              @remove="handleLogoUploadRemove"
            />
          </el-form-item>

          <el-tabs
            v-model="activeLang"
            type="card"
            closable
            @edit="handleTabsEdit"
          >
            <el-tab-pane
              v-for="(item, index) in shopForm.content"
              :key="item.index"
              :label="langName(item.langCode)"
              :name="item.langCode"
            >
              <el-form-item
                :label="`Relative Url [${item.langCode}]`"
                prop="relativeUrl"
              >
                <RelativeUrl
                  v-model="shopForm.content[index].relativeUrl"
                  @generate-relative-url="handleRelativeUrl($event, index, shopForm.shopName)"
                  @set-relative-url="setRelativeUrl($event, index)"
                />
              </el-form-item>

              <el-form-item
                :label="`Description [${item.langCode}]`"
                prop="description"
              >
                <Editor
                  v-model="shopForm.content[index].description"
                />
              </el-form-item>

              <el-form-item
                :label="`Meta Title [${item.langCode}]`"
                prop="metaTitle"
              >
                <el-input v-model="shopForm.content[index].metaTitle" />
              </el-form-item>

              <el-form-item
                :label="`Meta Description [${item.langCode}]`"
                prop="metaDescription"
              >
                <el-input
                  v-model="shopForm.content[index].metaDescription"
                  type="textarea"
                  resize="none"
                  :autosize="{ minRows: 2, maxRows: 8}"
                />
              </el-form-item>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>

        <el-tab-pane
          label="Variables"
          name="variables"
        >
          <el-tabs
            v-model="activeLang"
            type="card"
          >
            <el-tab-pane
              v-for="(item, index) in shopForm.variables"
              :key="item.index"
              :label="langOptions.find((l) => l.value === item.langCode)?.label"
              :name="item.langCode"
            >
              <el-form-item>
                <el-row :gutter="20">
                  <el-col :span="8">
                    <strong>
                      Variable
                    </strong>
                  </el-col>
                  <el-col :span="8">
                    <strong>
                      Game
                    </strong>
                  </el-col>
                  <el-col :span="8">
                    <strong>
                      Value
                    </strong>
                  </el-col>
                </el-row>

                <el-row
                  v-for="(value, index2) in shopForm.variables[index].values"
                  :key="index2"
                  :gutter="20"
                  class="margin-bottom-10"
                >
                  <el-col :span="8">
                    <el-input
                      v-model="value.name"
                      placeholder="Add new variable"
                      @input="editVariableName(index, index2)"
                    />
                  </el-col>
                  <el-col :span="8">
                    <el-select
                      v-model="value.gameUid"
                      filterable
                      :placeholder="getGameTitle(shopForm.variables?.[0].values?.[index2]?.gameUid) || 'All games'"
                      clearable
                    >
                      <el-option
                        v-for="item2 in games"
                        :key="item2.gameUid"
                        :label="item2.title"
                        :value="item2.gameUid"
                      />
                    </el-select>
                  </el-col>
                  <el-col :span="8">
                    <el-input
                      v-model="value.value"
                      :placeholder="shopForm.variables?.[0].values?.[index2]?.value || `Value`"
                      @input="editVariableName(index, index2)"
                    />
                  </el-col>
                </el-row>
              </el-form-item>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
      </el-tabs>

      <el-form-item class="flex-form__action-buttons">
        <el-button
          type="primary"
          :loading="saving"
          @click="onSubmit"
        >
          Save
        </el-button>

        <el-button @click="onCancel">
          Cancel
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  getShop,
  updateShop,
  createShop,
  generateRelativeUrl,
} from '@/api/shop';
import { getAllGames } from '@/api/game';
import { getAllShopPayments } from '@/api/shopPayment';
import { cloneDeep } from 'lodash';
import { makeUpdateObj } from '@/utils';
import Editor from '@/components/Editor';
import RelativeUrl from '@/components/RelativeUrl';
import { getAllLanguages } from '@/api/language';
import UploadMedia from '@/components/UploadMedia';

export default {
  name: 'ShopDetails',
  components: {
    UploadMedia,
    RelativeUrl,
    Editor,
  },
  props: {
    isEditMode: {
      default: false,
      type: Boolean,
    },
    shopUid: {
      default: null,
      type: String,
    },
    visible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      saving: false,
      shopForm: {
        shopName: '',
        content: [],
        variables: [],
        coverMediaUid: null,
        isActive: true,
        position: 0,
        iconMediaUid: null,
        logoMediaUid: null,
        provider: '',
        rating: 3,
        gameUids: [],
        paymentUids: [],
        gpPaymentUids: [],
        gpDepositUids: [],
      },
      shop: {},
      games: {},
      gameOptions: [],
      paymentOptions: [],
      gamesLoading: false,
      paymentsLoading: false,
      activeLang: '',
      langOptions: [],
      relativeUrlEdited: {},
      formTabsValue: 'main',
      rules: {
        shopName: [{
          required: true,
          trigger: 'blur',
          message: 'Name is required',
        }],
      },
    };
  },
  computed: {
    iconUrl() {
      return this.shopForm?.media?.icon?.image?.location;
    },
    logoUrl() {
      return this.shopForm?.media?.logo?.image?.location;
    },
  },
  watch: {
    visible() {
      if (this.shopUid) {
        this.getShop(this.shopUid);
      } else {
        this.initForm();
      }
    },
  },
  created() {
    if (this.isEditMode) {
      this.getShop(this.shopUid);
    } else {
      this.initForm();
    }
  },
  methods: {
    async initForm() {
      this.resetForm();

      await this.getGames();
      await this.getPayments();
      await this.getLanguages();

      this.langOptions.forEach((item) => {
        this.shopForm.content.push({
          langCode: item.value,
          relativeUrl: '',
          description: '',
          metaTitle: '',
          metaDescription: '',
        });

        this.shopForm.variables.push({
          langCode: item.value,
          values: [{
              name: '',
              gameUid: null,
              value: '',
            }],
        });
      });

      this.activeLang = this.langOptions[0].value;
    },
    langName(code) {
      const langName = this.langOptions.find((item) => item.value === code)?.label;
      const description = this.shopForm.content.find((item) => item.langCode === code)?.relativeUrl;

      if (!description.trim()) {
        return `! ${langName}`;
      }

      return langName;
    },
    async getShop(shopUid) {
      try {
        this.resetForm();
        this.loading = true;

        await this.getGames();
        await this.getPayments();
        await this.getLanguages();

        const data = await getShop({ shopUid });

        if (!data.shop?.content) {
          data.shop.content = [];
        }
        if (!data.shop?.variables) {
          data.shop.variables = [];
        }

        this.shop = cloneDeep(data.shop);
        this.shopForm = data.shop;

        // order content by langOptions
        this.shopForm.content = this.langOptions.map((item) => {
          const content = this.shopForm.content.find((c) => c.langCode === item.value);
          if (!content) {
            return {
              langCode: item.value,
              relativeUrl: '',
              description: '',
              metaTitle: '',
              metaDescription: '',
            };
          }

          return content;
        });

        if (this.shopForm?.variables?.[0]?.values) {
          this.shopForm.variables = this.langOptions.map((item) => {
            const variables = this.shopForm.variables.find((c) => c.langCode === item.value);
            if (!variables) {
              return {
                langCode: item.value,
                values: this.shopForm.variables[0].values.map((v) => ({
                  name: v.name || '',
                  gameUid: null,
                  value: '',
                })),
              };
            }

            return variables;
          });
        } else {
          this.shopForm.variables = this.langOptions.map((item) => ({
            langCode: item.value,
            values: [{
              name: '',
              gameUid: null,
              value: '',
            }],
          }));
        }

        this.shopForm.variables.forEach((item) => {
          if (item.values.length === 0) {
            item.values.push({
              name: '',
              gameUid: null,
              value: '',
            });
          }
        });

        this.activeLang = this.shopForm.content
          .find((item) => item.relativeUrl.trim())?.langCode || this.langOptions[0].value;

        this.loading = false;
      } catch (e) {
        this.loading = false;

        this.onCancel();

        throw e;
      }
    },
    async getLanguages() {
      try {
        const data = await getAllLanguages();
        this.langOptions = data.languages.map((item) => ({
          value: item.langCode,
          label: item.langName,
        }));
      } catch (e) {
        this.langOptions = [];
      }
    },
    async getGames() {
      try {
        this.gamesLoading = true;
        const data = await getAllGames();

        this.gameOptions = data.games.map((item) => ({
          value: item.gameUid,
          label: item.title,
        }));

        this.games = data.games;
        this.gamesLoading = false;
      } catch (e) {
        this.gamesLoading = false;

        this.onCancel();
      }
    },
    async getPayments() {
      try {
        this.paymentsLoading = true;
        const data = await getAllShopPayments();

        this.paymentOptions = data.shopPayments.map((item) => ({
          value: item.paymentUid,
          label: item.name,
        }));

        this.paymentsLoading = false;
      } catch (e) {
        this.paymentsLoading = false;

        this.onCancel();
      }
    },
    async onEditSubmit() {
      this.$refs.shopForm.validate(async (valid) => {
        if (valid) {
          const updatedShop = makeUpdateObj(this.shop, this.shopForm);
          if (Object.keys(updatedShop).length > 0) {
            try {
              this.saving = true;
              updatedShop.shopUid = this.shopUid;

              await updateShop(updatedShop);

              this.$message({
                type: 'success',
                message: 'Data has been saved successfully',
              });

              await this.getShop(this.shopUid);
              this.saving = false;

              this.$emit('update');
            } catch (e) {
              this.saving = false;
            }
          }

          this.close();
        }
      });
    },
    async onAddSubmit() {
      this.$refs.shopForm.validate(async (valid) => {
        if (valid) {
          try {
            this.saving = true;
            await createShop(this.shopForm);

            this.$message({
              type: 'success',
              message: 'Shop has been added successfully',
            });

            // this.resetForm();
            this.saving = false;

            this.$emit('update');
            this.close();
          } catch (e) {
            this.saving = false;
          }
        }
      });
    },
    async onSubmit() {
      if (this.isEditMode) {
        await this.onEditSubmit();
      } else {
        await this.onAddSubmit();
      }
    },
    resetForm() {
      this.shop = {};
      this.shopForm.content = [];
      this.shopForm.variables = [];
      this.shopForm.media = {};
      this.relativeUrlEdited = {};

      this.formTabsValue = 'main';

      this.$nextTick(() => {
        this.$refs.shopForm.resetFields();
        this.$refs.logoImage.reset();
        this.$refs.iconImage.reset();
      });
    },
    onCancel() {
      this.close();
    },
    close() {
      this.$emit('update:visible', false);
    },
    handleTabsEdit(targetName) {
      // remove content
      const index = this.shopForm.content.findIndex((item) => item.langCode === targetName);

      this.shopForm.content[index].relativeUrl = '';
      this.shopForm.content[index].metaTitle = '';
      this.shopForm.content[index].metaDescription = '';
      this.shopForm.content[index].description = '';

      // this.removeStaticPageRules(index);
    },
    handleLogoUploadSuccess(data) {
      this.shopForm.logoMediaUid = data.mediaUid;
    },
    handleLogoUploadRemove() {
      this.shopForm.logoMediaUid = null;
      this.shopForm.media.logo = null;
    },
    handleIconUploadSuccess(data) {
      this.shopForm.iconMediaUid = data.mediaUid;
    },
    handleIconUploadRemove() {
      this.shopForm.iconMediaUid = null;
      this.shopForm.media.icon = null;
    },
    async handleRelativeUrl(editRelativeUrl, index, value) {
      if (this.shopUid && !editRelativeUrl) {
        return;
      }

      if (value && value.trim().length) {
        const data = await generateRelativeUrl({
          name: value,
          shopUid: this.shopUid,
          langCode: this.shopForm.content[index].langCode,
        });
        if (data.relativeUrl) {
          this.shopForm.content[index].relativeUrl = data.relativeUrl;
        }
      }
    },
    setRelativeUrl(value, index) {
      this.relativeUrlEdited[index] = true;
      this.handleRelativeUrl(true, index, value);
    },
    getGameTitle(gameUid) {
      return this.games.find((item) => item.gameUid === gameUid)?.title;
    },
    editVariableName(varIndex, valIndex) {
      const { name } = this.shopForm.variables[varIndex].values[valIndex];

      this.shopForm.variables.forEach((item, index) => {
        this.shopForm.variables[index].values[valIndex].name = name.trim().toLowerCase();

        // add new variable if last
        if (valIndex === this.shopForm.variables[index].values.length - 1) {
          this.shopForm.variables[index].values.push({
            name: '',
            gameUid: null,
            value: '',
          });
        }
      });

      let isEmpty = true;

      if (!name?.trim()?.length) {
        this.shopForm.variables.forEach((item, index) => {
          if (isEmpty) {
            isEmpty = !this.shopForm.variables[index].values[valIndex].name.length
              && !this.shopForm.variables[index].values[valIndex].value.length;
          }
        });

        if (isEmpty) {
          this.shopForm.variables.forEach((item, index) => {
            this.shopForm.variables[index].values.splice(valIndex, 1);
          });
        }
      }
    },
  },
};
</script>
