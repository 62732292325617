import Vue from 'vue';

export function listShopPayments(params) {
  return Vue.$http({
    url: '/shopPayments/listShopPayments',
    method: 'get',
    params,
  });
}

export function getShopPayment(params) {
  return Vue.$http({
    url: '/shopPayments/getShopPayment',
    method: 'get',
    params,
  });
}

export function getAllShopPayments(params) {
  return Vue.$http({
    url: '/shopPayments/getAllShopPayments',
    method: 'get',
    params,
  });
}

export function updateShopPayment(data) {
  return Vue.$http({
    url: '/shopPayments/updateShopPayment',
    method: 'post',
    data,
  });
}

export function createShopPayment(data) {
  return Vue.$http({
    url: '/shopPayments/createShopPayment',
    method: 'post',
    data,
  });
}

export function deleteShopPayment(data) {
  return Vue.$http({
    url: '/shopPayments/deleteShopPayment',
    method: 'post',
    data,
  });
}
